import React from 'react';
import { useParams } from 'react-router-dom';
import RI001 from 'pages/reference-implementation/provisioning/RI001';
import RI002 from 'pages/reference-implementation/provisioning/RI002';
import RI003 from 'pages/reference-implementation/provisioning/RI003';
import RI004O from 'pages/reference-implementation/provisioning/RI004O';
import RI003O from 'pages/reference-implementation/provisioning/RI003O';
import RI002O from 'pages/reference-implementation/provisioning/RI002O';
import {
    RICatalogCode001,
    RICatalogCode002,
    RICatalogCode003,
    RICatalogCode004,
    RICatalogCode005,
    RICatalogCode004A,
    RICatalogCode003A,
    RICatalogCode001A,
    RICatalogCode003O,
    RICatalogCode004O,
    RICatalogCode002O,
} from 'pages/reference-implementation/utils/RICatalogCode';
import { allowedServices } from 'utils';
import { PageNotFound } from 'pages';

const RiProvisioning = () => {
    const { catalogCode } = useParams();
    return (
        <>
            {catalogCode && [RICatalogCode001, RICatalogCode001A]?.includes(catalogCode) && allowedServices(catalogCode) && <RI001 />}
            {catalogCode && catalogCode === RICatalogCode002 && allowedServices(catalogCode) && <RI002 />}
            {catalogCode && catalogCode === RICatalogCode003O && allowedServices(catalogCode) && <RI003O />}
            {catalogCode && catalogCode === RICatalogCode002O && allowedServices(catalogCode) && <RI002O />}
            {catalogCode && catalogCode === RICatalogCode004O && allowedServices(catalogCode) && <RI004O />}
            {catalogCode &&
                [RICatalogCode003, RICatalogCode003A, RICatalogCode004, RICatalogCode004A, RICatalogCode005]?.includes(catalogCode) &&
                allowedServices(catalogCode) && <RI003 />}
            {!allowedServices(catalogCode) && <PageNotFound />}
        </>
    );
};

export default React.memo(RiProvisioning);
